.settingsBtn {
  margin-left: 19px !important;

  .settings {
    fill: #464646;
  }
}

.first500Badge {
  width: 57px;
  margin-right: 10px;
}
