@import '../../variables.scss';

.root {
  margin-bottom: 10px;
  width: calc(100% - 20px);
  display: flex;
  align-self: center;
  border-radius: 15px;
  box-shadow: 2px 1px 11px 3px rgba(181, 181, 181, 0.4);

  .wrapper {
    display: flex;
    align-items: center;
    min-height: 87px;
    background: $white;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: $border_radius;
    width: 100%;

    .content {
      display: flex;
      align-items: center;
      width: 100%;

      .textContainer {
        margin-right: auto;
        width: 475px;

        .title {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 14px;
          color: $gray_two;
          height: 38px;
        }

        .subtitle {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: $gray_one;
          margin-top: 5px;
        }

        .info {
          margin-bottom: 20px;
          font-size: 12px;
          line-height: 18px;
          color: $gray_one;
        }
      }

      .filename {
        margin-left: 20px;
        font-size: 13px;
        color: $gray_one;
      }

      .relContent {
        display: flex;
        align-items: center;
        position: relative;
        margin-left: 20px;

        .loader {
          width: 97%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 30px;
          left: 3px;
        }
      }

      .buttonContainer {
        display: flex;
        align-items: center;
        margin-top: 40px;

        .empty {
          display: block;
          height: 60px;
          width: 283px;
        }

        .btnUpload {
          cursor: pointer;
          width: 282px;
          height: 45px;
          border-radius: $border_radius;
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          text-align: center;
          background: $white;
          color: $gray_two;
          border: 2px solid $gray_one;
          margin-left: 10px;
        }
      }
    }
  }

  .uploadContent {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1260px;
    background: $white;

    .subcontent {
      width: 100%;
      padding: 0px 20px 20px 20px;

      .line {
        width: 100%;
        height: 1px;
        background: #858585;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;

        .button {
          margin-right: 10px;
          margin-left: 10px;
        }
      }
    }
  }
}

.infoWrapper {
  display: flex;
  flex-direction: column;

  .content {
    display: flex;
    width: 100%;

    .selectContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 64px;

      .select {
        margin: 0 10px;

        label {
          color: $gray_one;
          font-size: 14px;
          font-family: 'Montserrat', sans-serif;
        }

        select {
          padding-left: 10px;
        }
      }
    }
  }

  .line {
    width: 100%;
    height: 1px;
    background: #858585;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .writers,
  .publishers {
    .title {
      font-size: 14px;
      font-weight: bold;
    }
  }

  .cont {
    display: flex;
  }
}

.itemInput {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0px 10px;
}

.popoverContent,
.popoverContentInfo {
  padding: 10px;
  height: 108px;
  color: $gray_one;
  font-size: 13px;

  .item {
    padding: 5px;
    font-weight: bold;
    display: flex;
    align-items: center;

    .icon {
      padding: 5px;
      border-radius: 50%;
      border: 1px solid #3f51b5;
      margin-left: 5px;
      margin-right: 5px;
      color: $gray_one;
    }
  }
}

.popupBtn {
  display: none;
}

.faqIcon {
  color: $gray_one;
}

.storageContainer {
  width: 100%;

  .head {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: $gray_two;
    margin-bottom: 5px;
  }

  .storageItem {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #8d96b2;

    .size {
      margin-right: 10px;
      margin-left: 10px;
      font-weight: bold;
    }
  }
}

.linkNotActive {
  width: 200px;
  color: red;
  font-size: 13px;
  display: block;
}

.notActive {
  display: flex;
  align-items: center;
}

.bold,
.isActive {
  font-weight: bold;
  margin-right: 5px;
}

.notActiveLink {
  font-weight: bold;
  color: red;
}

.popoverContentInfo {
  width: 250px;
}
